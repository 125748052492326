<template>
  <WithOldVetBar>
    <div class="BrandSection mt-5">
      <h2 data-unit="title" class="brand-h2">Reset Password</h2>
      <div v-if="!this.showPasswordResetSuccessSection" data-unit="initial-view">
        <div data-unit="instructions">
          For security, the password must contain:
          <ul class="conditions">
            <li>
              At least <b>8 caracters</b>
              <span class="condition-valid" v-if="atLeastEightChars">✔️</span><span v-else></span>
            </li>
            <li>
              At least <b>one number</b>
              <span class="condition-valid" v-if="containsNumber">✔️</span><span v-else></span>
            </li>
            <li>
              At least <b>one uppercase</b>
              <span class="condition-valid" v-if="containsUpperCase">✔️</span><span v-else></span>
            </li>
            <li>
              At least <b>one lowercase</b>
              <span class="condition-valid" v-if="containsLowercase">✔️</span><span v-else></span>
            </li>
            <li>
              At least <b>one symbol (@#$%^!+=)</b>
              <span class="condition-valid" v-if="containsSymbol">✔️</span><span v-else></span>
            </li>
            <li>
              Repeat password should match
              <span class="condition-valid" v-if="passwordsAreMatching">✔️</span
              ><span v-else></span>
            </li>
          </ul>
        </div>
        <form
          @submit="onChangePasswordButtonClicked"
          data-unit="new-password-form"
          class="mt-2"
          data-hj-suppress
        >
          <div class="my-3 w-50">
            <h3 class="brand-h3">New password</h3>
            <div class="form-group">
              <label for="new" class="d-none">New Password</label>
              <input
                id="new"
                type="password"
                class="form-control brand-input"
                data-unit="new-password"
                v-model="passwordResetForm.newPassword"
                required=""
              />
            </div>
          </div>
          <div class="my-3 w-50">
            <h3 class="brand-h3">Repeat new password</h3>
            <div class="form-group">
              <label for="repeat" class="d-none">Repeat Password </label>
              <input
                id="repeat"
                class="form-control brand-input"
                type="password"
                data-unit="repeat-password"
                v-model="passwordResetForm.repeatNewPassword"
                required=""
              />
            </div>
          </div>
          <div
            data-unit="error-alert"
            v-if="showPasswordResetErrorSection"
            class="alert alert-danger w-75"
          >
            {{ this.errorMessage }}
            <router-link v-if="this.expiredToken" to="/request-password-reset">
              Request new password reset.
            </router-link>
          </div>
          <button
            class="btn brand-button mt-2 w-50"
            type="submit"
            data-unit="set-new-password-button"
          >
            Change my password
          </button>
        </form>
      </div>
      <div v-else data-unit="success-view">
        <div>
          You changed your password successfully!
        </div>
        <br />
        <router-link data-unit="login-cta" class="btn brand-button-cta" to="/login"
          >Log in Now</router-link
        >
      </div>
    </div>
  </WithOldVetBar>
</template>

<script>
import Vue from "vue";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";

export default Vue.extend({
  name: "SetNewPassword",
  props: [],
  inject: ["Api"],
  data() {
    return {
      passwordResetForm: {
        newPassword: "",
        repeatNewPassword: ""
      },
      showPasswordResetSuccessSection: false,
      showPasswordResetErrorSection: false,
      errorMessage: "",
      expiredToken: false
    };
  },
  computed: {
    containsNumber() {
      return /\d/.test(this.passwordResetForm.newPassword);
    },
    containsUpperCase() {
      return /[A-Z]/.test(this.passwordResetForm.newPassword);
    },
    containsLowercase() {
      return /[a-z]/.test(this.passwordResetForm.newPassword);
    },
    containsSymbol() {
      return /[!@#$%^&*_=+-]/.test(this.passwordResetForm.newPassword);
    },
    atLeastEightChars() {
      return /^.{8,}/.test(this.passwordResetForm.newPassword);
    },
    passwordsAreMatching() {
      return (
        this.passwordResetForm.newPassword &&
        this.passwordResetForm.newPassword === this.passwordResetForm.repeatNewPassword
      );
    }
  },
  components: { WithOldVetBar },
  methods: {
    async requestNewPasswordSet(newPassword, token) {
      this.showPasswordResetErrorSection = false;
      this.showPasswordResetSuccessSection = false;
      this.errorMessage = "";
      this.expiredToken = false;
      const result = await this.Api.setNewPassword(newPassword, token);
      if (result.success) {
        this.showPasswordResetSuccessSection = true;
      } else {
        this.showPasswordResetErrorSection = true;
        this.errorMessage = result.errorMessage;
        this.expiredToken = result.expiredToken;
      }
    },
    isValidPassword() {
      return (
        this.containsNumber &&
        this.containsUpperCase &&
        this.containsLowercase &&
        this.containsSymbol &&
        this.atLeastEightChars &&
        this.passwordsAreMatching
      );
    },
    async onChangePasswordButtonClicked(event) {
      event.preventDefault();
      if (this.isValidPassword()) {
        await this.requestNewPasswordSet(
          this.passwordResetForm.newPassword,
          this.$route.query.token
        );
      }
    }
  }
});
</script>
<style>
.condition-valid {
  color: green;
}
.conditions span {
  font-size: 25px;
}
</style>
